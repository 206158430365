@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/Gilroy-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/Gilroy-RegularItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/Gilroy-ExtraBold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy/Gilroy-ExtraBoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Blacker';
  src: url('/fonts/Blacker/Blacker-Display-Heavy.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Blacker';
  src: url('/fonts/Blacker/Blacker-Display-HeavyItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}
